import { Component, Input } from '@angular/core';
import { DrawerSettingModel, SettingService } from '@core/setting.service';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
    selector: 'app-sh-document-viewer',
    template: `
        <div class="sh-document-viewer">
            <button *ngIf="(settingService.loading$ | async) === false" class="sh-document-viewer__download-button" (click)="downloadFile()" mat-fab color="warn">
                <span class="sh-document-viewer__download-button-icon" [inlineSVG]="'./assets/icons/new-download.svg'" aria-hidden="true"></span>
            </button>
            <mat-spinner *ngIf="(settingService.loading$ | async) === true" class="sh-document-viewer__spinner"></mat-spinner>
            <pdf-viewer [src]="drawerSetting?.data?.href" [style]="{width: '100%', height: '100%'}" (after-load-complete)="loadComplete($event)"></pdf-viewer>
        </div>
    `,
})
export class ShDocumentViewerComponent {
    @Input() public drawerSetting?: DrawerSettingModel;

    constructor(
        public settingService: SettingService,
    ) {}
    public loadComplete(pdf: PDFDocumentProxy) {
        if (pdf) {
            this.settingService._loadingSubject.next(false);
        }
    }

    public downloadFile() {
        if (this.drawerSetting?.data) {
            this.drawerSetting.data.click();
        }
    }
}
