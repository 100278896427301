import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
} from '@angular/core';
import { TableLinkType } from '@models/interfaces';
import { findIndex, get, orderBy } from 'lodash-es';
import { isObservable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TableCellContentDirective } from './table-cell-content.directive';
import { ColumnHead, RowMetadata, TableModel } from './table.model';
import { MarketingFileIdAndType } from '@features/marketing-files/marketing-files.component';

@Component({
    selector: 'app-sh-table',
    templateUrl: './sh-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShTableComponent implements OnInit, OnDestroy, AfterContentChecked, OnChanges {
    private readonly unsubscribe = new Subject<void>();

    private _tableRows: RowMetadata<any[]>[] = [];
    private _tableFullData: RowMetadata<any[]>[] = []; // used for client side
    private _tableHeader: ColumnHead<any>[];

    private orderControl: ColumnHead<any>[] = [];
    private isSelectAll = false;
    private pageStart: number;
    private pageEnd: number;
    private firstLoad = true;

    @Input() public idField: string;
    @Input() public allowSort = false;
    @Input() public isTransparentHeader = false;
    @Input() public rowStriping = false;
    @Input() public isSelectable = false;
    @Input() public disabled = false;
    @Input() public hasPaginator = false;
    @Input() public hidePageSize = false;
    @Input() public showFirstLastButtons = false;
    @Input() public pageIndex = 1;
    @Input() public pageSize = 10;
    @Input() public pageSizeOptions: number[];
    @Input() public name: string;
    @Input() public trackById: string;
    @Input() public set tableModel(t: any) {
        if (!t) {
            return;
        }
        const v = t as TableModel<any>;
        if (isObservable(v.rowData)) {
            v.rowData.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
                if (this.hasPaginator) {
                    this._tableFullData = data;
                    this._tableRows = this.getPageData();
                } else {
                    this._tableRows = data;
                }
                this.checkDataSizePaginator();
            });
        } else {
            if (this.hasPaginator) {
                this._tableFullData = v.rowData;
                this._tableRows = this.getPageData();
            } else {
                this._tableRows = v.rowData;
            }
            this.checkDataSizePaginator();
        }
        this._tableHeader = v.headerConfig;
        this.trackById = (v.idField as string) || '';
    }

    @Output() public sortChange: EventEmitter<ColumnHead<any>[]> = new EventEmitter();
    @Output() public linkClicked: EventEmitter<TableLinkType> = new EventEmitter();
    @Output() public marketingFileIdClicked: EventEmitter<MarketingFileIdAndType> = new EventEmitter();
    @Output() public marketingFileIdClickedDownload: EventEmitter<MarketingFileIdAndType> = new EventEmitter();
    @Output() public selectionChange: EventEmitter<RowMetadata<any> | RowMetadata<any>[]> = new EventEmitter();

    @ContentChildren(TableCellContentDirective)
    public tableCellTemplates: QueryList<TableCellContentDirective>;

    public totalPages: number;
    public templates: unknown[] = [];
    public usingPaginator = this.hasPaginator;

    public get tableRows() {
        return this._tableRows;
    }

    public get columns() {
        return this._tableHeader ? this._tableHeader : null;
    }

    constructor(private cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        const ps = this.getPageSizeFromLocalStorage();
        if (ps) {
            this.pageSize = ps;
        } else {
            this.setPageSizeToLocalStorage();
        }
    }

    public ngAfterContentChecked(): void {
        this.columns?.forEach(col => {
            if (col.templateRefName) {
                this.templates[col.templateRefName as any] = this.tableCellTemplates.filter(
                    t => t.appTableCellContent === col.templateRefName
                )[0].templateRef;
            }
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public ngOnChanges(changes: SimpleChanges) {
        const change = changes.tableModel.currentValue?.rowData;

        if (change.length) {
            // Set product name as default sort value
            if (this.firstLoad && this.columns?.length) {
                this.orderControl = [this.columns[0]];
                this.firstLoad = false;
            }
            this.sortTable();
        }
    }

    public onSortClick(column: ColumnHead<any>): void {
        if (!this.allowSort || this.disabled || column.disableSorting) {
            return;
        }
        const columnsOrdered = this.orderControl.map(col => col.fieldID);
        const columnIndex = columnsOrdered.indexOf(column.fieldID);
        if (columnIndex > -1) {
            const orderBy = this.orderControl[columnIndex].orderByDesc;
            if (orderBy) {
                // Remove sort on third click
                this.orderControl.splice(columnIndex, 1);
            } else {
                const auxColumn: ColumnHead<any> = {
                    ...column,
                    orderByDesc: !orderBy,
                };
                this.orderControl.splice(columnIndex, 1, auxColumn);
            }
        } else {
            const auxColumn: ColumnHead<any> = {
                ...column,
                orderByDesc: false,
            };
            this.orderControl.push(auxColumn);
        }

        this.sortTable();
        this.sortChange.emit(this.orderControl);
    }

    public sortTable() {
        const columns = this.orderControl.map(col => col.fieldID);
        const orders = this.orderControl.map(col => (col.orderByDesc ? 'desc' : 'asc'));

        this._tableRows.forEach(row => {
            columns.forEach((column, i) => {
                let currentColumn = column as any;
                if (Array.isArray(row[currentColumn])) {
                    currentColumn = `${currentColumn}[0].label`;
                } else if (row[currentColumn]?.label) {
                    currentColumn = `${currentColumn}.label`;
                }

                columns[i] = currentColumn;
            });
        });

        this._tableRows = orderBy(
            this._tableRows,
            columns.map(column => {
                return row => {
                    const value = get(row, column);
                    return value != null ? value.toString().toLowerCase() : '';
                };
            }),
            orders
        ) as any;
    }

    public onCheckChange(value: any): void {
        if (!this._tableRows || !this._tableRows.length) {
            return;
        }

        const indexFound = findIndex(this._tableRows, o => o[this.idField as any] === value[this.idField]);

        if (indexFound > -1) {
            this._tableRows[indexFound].selected = !this._tableRows[indexFound].selected;
        } else {
            console.warn('TABLE ERROR: item not found');
        }

        this.selectionChange.emit(this._tableRows[indexFound]);
    }

    public onSelectAll(): void {
        this.isSelectAll = !this.isSelectAll;

        if (this.isSelectAll) {
            this._tableRows = this._tableRows.map(row => ({
                ...row,
                selected: true,
            }));
        } else {
            this._tableRows = this._tableRows.map(row => ({
                ...row,
                selected: false,
            }));
        }

        this.selectionChange.emit(this._tableRows);
    }

    public isOrderByDesc(column: ColumnHead<any>): string {
        let result = '';

        if (this.hasSort(column.fieldID.toString())) {
            result = this.orderControl.some(control => control.fieldID === column.fieldID && control.orderByDesc) ? 'desc' : 'asc';
        }

        return result;
    }

    public onPageChange(page: number): void {
        this.pageIndex = page;
        this._tableRows = this.getPageData();
    }

    public onPageSizeChange(pageSize: number): void {
        this.pageSize = pageSize;
        this.pageIndex = 1;
        this._tableRows = this.getPageData();
        this.setPageSizeToLocalStorage();
    }

    public onLinkClicked(link: TableLinkType): void {
        this.linkClicked.emit(link);
    }

    public onMarketingFileIdClicked(fileIdAndType: MarketingFileIdAndType): void {
        this.marketingFileIdClicked.emit(fileIdAndType);
    }

    public onMarketingFileIdClickedDownload(fileIdAndType: MarketingFileIdAndType): void {
        this.marketingFileIdClickedDownload.emit(fileIdAndType);
    }

    public trackRowByFn = (index: number, item: RowMetadata<any>) => {
        return this.trackById ? item[this.trackById] || index : index;
    };

    private hasSort(column: string): boolean {
        return this.orderControl.some(control => control.fieldID === column);
    }

    private getPageData() {
        this.totalPages = Math.ceil(this._tableFullData.length / this.pageSize);
        this.pageStart = this.pageIndex === 1 ? 0 : (this.pageStart = this.pageEnd);
        this.pageEnd = this.pageStart + this.pageSize;
        return this._tableFullData.slice(this.pageStart, this.pageEnd);
    }

    private setPageSizeToLocalStorage(): void {
        if (this.name) {
            localStorage.setItem(`tablePageSize-${this.name}`, this.pageSize.toString());
        } else {
            localStorage.setItem('tablePageSize', this.pageSize.toString());
        }
    }

    private getPageSizeFromLocalStorage(): number {
        const pageSize = this.name ? localStorage.getItem(`tablePageSize-${this.name}`) : localStorage.getItem('tablePageSize');
        return pageSize ? +pageSize : this.pageSize;
    }

    // This function will verify if all data is smaller than one page size to disable the paginator
    private checkDataSizePaginator(): void {
        if (this.usingPaginator && this._tableFullData.length <= this.pageSize && this._tableRows.length) {
            this.usingPaginator = false;
        } else {
            this.usingPaginator = this.hasPaginator;
        }
        this.pageIndex = 1;
        this.cdr.markForCheck();
    }
}
