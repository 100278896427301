import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontpageComponent } from '@features/frontpage/frontpage.component';
import { ProductCatalogueComponent } from '@features/product-catalogue/product-catalogue.component';
import { ProductPageComponent } from '@features/product-page/product-page.component';
import { SolutionFinderComponent } from '@features/solution-finder/solution-finder.component';
import { MarketingFilesComponent } from '@features/marketing-files/marketing-files.component';

const routes: Routes = [
    { path: '', component: FrontpageComponent },
    { path: 'products', component: ProductCatalogueComponent },
    { path: 'solution-finder', component: SolutionFinderComponent },
    { path: 'marketing-files', component: MarketingFilesComponent },
    { path: 'products/:id', component: ProductPageComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            scrollOffset: [0, 0],
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
