import { Injectable } from '@angular/core';
import { ApplicationArea, FilterInput, FilterItemType, FilterResult, QueryTypeList, Spinner } from '@models/interfaces';
import { fromWorker } from 'observable-webworker';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { auditTime, share } from 'rxjs/operators';

import { MarketingFileType, ProductType, RecipeType, StorageTypes } from '../models/types';

@Injectable({
    providedIn: 'root',
})
export class DataFilterService {
    public currentApplicationArea = new ReplaySubject<ApplicationArea | undefined>(1);
    public data = new BehaviorSubject<StorageTypes[]>([]);
    public spinner = new BehaviorSubject<Spinner>({ isActive: false });
    public spinnerObs = this.spinner.asObservable();

    public static isProductTypes(data: StorageTypes[]): data is ProductType[] {
        return (data as ProductType[]).length > 0 && !!(data as ProductType[])[0].hasOwnProperty('variants');
    }

    public static isRecipeTypes(data: StorageTypes[]): data is RecipeType[] {
        return (data as RecipeType[]).length > 0 && !!(data as RecipeType[])[0].hasOwnProperty('recipeFile');
    }

    public static isMarketingFileTypes(data: StorageTypes[]): data is MarketingFileType[] {
        return (data as MarketingFileType[]).length > 0 && !!(data as MarketingFileType[])[0].hasOwnProperty('fileName');
    }

    public static getMustBeFilter(): FilterItemType {
        return {
            field: 'mustBe',
            values: [
                {
                    key: 'kosher',
                    label: 'Kosher',
                    checked: false,
                },
                {
                    key: 'halal',
                    label: 'Halal',
                    checked: false,
                },
                {
                    key: 'palmFree',
                    label: 'Palm free',
                    checked: false,
                },
                {
                    key: 'sg',
                    label: 'SG',
                    checked: false,
                },
                {
                    key: 'transFatFree',
                    label: 'PHO free',
                    checked: false,
                },
                {
                    key: 'vegetable',
                    label: 'Vegetable',
                    checked: false,
                },
            ],
        };
    }

    public filter(data: StorageTypes[], query: QueryTypeList): Observable<FilterResult> {
        if (!Object.keys(query).length) {
            return of({ data, query, queryCount: 0 });
        }

        return fromWorker<FilterInput, FilterResult>(
            () =>
                new Worker(new URL('./data-filter.worker', import.meta.url), {
                    type: 'module',
                }),
            of({ data, query })
        ).pipe(
            auditTime(1000 / 60), // emit results at a maximum of 60fps
            share()
        );
    }
}
