import {Injectable, OnDestroy} from '@angular/core';
import {StorageService} from '@core/storage.service';
import {PortalsService} from '@features/portals/portals.service';
import {DataProviderResponse} from '@models/interfaces';
import {addHours, addMinutes, isBefore, subDays} from 'date-fns';
import {BehaviorSubject, interval, merge, ReplaySubject, Subject} from 'rxjs';
import {bufferTime, concatMap, filter, takeUntil, tap} from 'rxjs/operators';
import {EnvironmentService} from "@core/environment.service";
import {AuthenticationService} from './authentication.service';
import {NotificationService} from './notification.service';

@Injectable({
    providedIn: 'root',
})
export class DataProviderService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    endpoint = '/api';
    private worker: Worker;

    private reportQueue$ = new ReplaySubject<string>(100);
    private stateLog$ = new BehaviorSubject<DataProviderResponse>({
        type: 'status',
        state: 'complete',
    });

    constructor(
        private readonly store: StorageService,
        private readonly auth: AuthenticationService,
        private readonly portals: PortalsService,
        private readonly notification: NotificationService,
        private env: EnvironmentService
    ) {
        const accountWithOfflineState$ = this.auth.accountInformation.pipe(
            filter(account => !!account),
            tap(account => {
                // If newly logged-in account does not have offline access
                // make sure to disable access and re-run sync to delete files
                if (!account?.hasOfflineAccess) {
                    this.store.setSettings('offline', 0);
                }
            })
        );

        const oncePerHour$ = interval(3600000);
        const everyTenMinutes = interval(600000);

        merge(accountWithOfflineState$, this.portals.portalObs, (this.env.getEnvironment() !== "PROD") ? everyTenMinutes : oncePerHour$)
            .pipe(
                concatMap(async () => {
                    const lastSync = await this.store.getSettings(this.portals.getCurrentPortal()).then(setting => setting?.lastSync);
                    if (lastSync) {
                        const shouldUpdate =
                            (await this.checkTimeSinceLastUpdate(lastSync)) ||
                            !(await this.store.hasData('products')) ||
                            !(await this.store.hasData('recipes'));

                        if (shouldUpdate) {
                            this.fetchData();
                        }
                    } else {
                        this.fetchData();
                    }
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    async fetchData() {
        if (!this.auth.isLoggedIn.value) {
            return;
        }

        try {
            if (this.worker) {
                this.worker.terminate();
            }

            const url = this.endpoint;
            const authToken = this.auth.authToken;

            this.worker = new Worker(new URL('./data-provider.worker', import.meta.url), { type: 'module' });

            this.listenForWorker(this.worker);

            this.worker.postMessage({
                type: 'data',
                url,
                authToken,
            });
        } catch (error: any) {
            console.warn('Could not fetch data', error);
        }
    }

    getReportQueue(bufferInMs = 250) {
        return this.reportQueue$.asObservable().pipe(bufferTime(bufferInMs));
    }

    getState() {
        return this.stateLog$.asObservable();
    }

    stop() {
        if (this.worker) {
            this.worker.terminate();
        }
    }

    private listenForWorker(worker: Worker): void {
        worker.onmessage = async ({ data }: { data: DataProviderResponse }) => {
            switch (data.type) {
                case 'log':
                    this.reportQueue$.next(data.message ?? '');
                    break;

                case 'status':
                    console.log('status', data.state, data.message);
                    this.stateLog$.next(data);

                    // Refresh data on complete.
                    if (data.state === 'complete') {
                        // We need to wait a short while for everything to settle before updating.
                        setTimeout((): void => {
                            this.store.getRecipes();
                            this.store.getProducts();
                            this.store.getApplicationAreas();
                        }, 10);
                        this.store.setSettings('lastSync', new Date().toISOString());
                    } else if (data.state === 'deviceoutofdiskspace') {
                        this.stop();

                        const availableDiskSpace = await this.store.estimateAvailableDiskSpace();

                        const msg = `The device ran out of allocated disk space (available: ${
                            availableDiskSpace ? this.store.formatDiskSpace(availableDiskSpace) : 'N/A'
                        }. Try to delete some files and refresh the webpage`;
                        this.reportQueue$.next(msg);
                        this.notification
                            .openSnackBar(msg, undefined, 'reload')
                            .onAction()
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe(() => window.location.reload());
                    }
                    break;
            }
        };
    }

    async checkTimeSinceLastUpdate(lastSync: string): Promise<boolean> {
        // Check to see if last sync was before 8am yesterday

        const env = this.env.getEnvironment();
        const now = new Date();
        const lastSyncDate = new Date(lastSync);
        if(env !== "PROD"){
            return isBefore(addMinutes(lastSyncDate, 10), now);
        }
        const twentyFourHoursAgo = subDays(now, 1);
        const lastSyncPlusTwoHours = addHours(lastSyncDate, 2);
        const today8AM = new Date();
        today8AM.setHours(8, 0, 0);
        const today8AMDanishTime = new Date(
            today8AM.toLocaleString(undefined, {
                timeZone: 'Europe/Copenhagen',
            })
        );

      return isBefore(lastSyncPlusTwoHours, now) ||
          (isBefore(today8AMDanishTime, now) && isBefore(lastSyncDate, today8AMDanishTime)) ||
          isBefore(lastSyncDate, twentyFourHoursAgo);
    }
}
