<div #productPage class="product-page" *ngIf="tabData">
    <div class="product-page__header">
        <a class="product-page__close-button" (click)="closeDialog()">
            <span>X</span>
        </a>
        <h1 class="product-page__title">{{ data?.name }}</h1>
        <app-sh-tabs (tabContent)="onTabData($event)" [tabData]="tabData"></app-sh-tabs>
    </div>
    <div class="product-page__content">
        <app-sh-panel>
            <app-sh-panel-content [ngSwitch]="activeTab?.component">
                <div *ngSwitchCase="'table'">
                    <app-sh-table
                        [tableModel]="activeTab?.tabData"
                        idField="id"
                        isTransparentHeader="true"
                        [disabled]="true"
                        (linkClicked)="onLinkClicked($event)"
                    >
                        <ng-template appTableCellContent="iconTpl" let-row="row">
                            <div class="product-page__icons">
                                <img alt [attr.src]="icon.src" [attr.title]="icon.title" *ngFor="let icon of row" />
                            </div>
                        </ng-template>
                    </app-sh-table>
                </div>

                <div *ngSwitchCase="'text'">
                    <h1 class="text__heading--paragraph">
                        {{ activeTab?.tabData?.title }}
                    </h1>
                    <p class="text__paragraph">
                        {{ activeTab?.tabData?.description }}
                    </p>
                </div>

                <div *ngSwitchCase="'productProfile'" class="product-page__container">
                    <div class="product-page__profiles" *ngIf="filteredProductFiles?.length">
                        See full product profile: (
                        <ng-container *ngFor="let link of sortLanguages(filteredProductFiles); let last = last">
                            <a
                                *ngIf="link.externalLink"
                                class="sh-button__link sh-button__link--has-partition"
                                [href]="link.route"
                                target="_blank"
                                (click)="onLinkClicked(link, $event)"
                            >
                                {{ link.label }}
                            </a>
                            <a
                                *ngIf="!link.externalLink"
                                class="sh-button__link sh-button__link--has-partition"
                                [routerLink]="link.route"
                                [queryParams]="link.routeParams"
                                [skipLocationChange]="link.skipLocationChange || false"
                                (click)="onLinkClicked(link, $event)"
                            >
                                {{ link.label }}
                            </a>
                            <span *ngIf="!last">/</span>
                        </ng-container>
                        )
                    </div>

                    <div class="text__paragraph-container text__paragraph-container--slim">
                        <ng-container *ngIf="productProfile?.productType">
                            <div class="text__paragraph-item">
                                <h3 class="text__heading--paragraph text__heading--small">Product type</h3>
                                <p>{{ productProfile?.productType }}</p>
                            </div>

                            <hr />
                        </ng-container>

                        <ng-container *ngIf="productProfile?.applicationAreas">
                            <div class="text__paragraph-item">
                                <h3 class="text__heading--paragraph text__heading--small">Application areas</h3>
                                <p>{{ productProfile?.applicationAreas }}</p>
                            </div>

                            <hr />
                        </ng-container>

                        <ng-container *ngIf="productProfile?.functionalProperties">
                            <div class="text__paragraph-item">
                                <h3 class="text__heading--paragraph text__heading--small">Functional properties</h3>
                                <p>{{ productProfile?.functionalProperties }}</p>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </app-sh-panel-content>
        </app-sh-panel>
    </div>
</div>
<div #productPage class="product-page" *ngIf="!tabData">
    <div class="product-page__header">
        <a class="product-page__close-button" (click)="closeDialog()">
            <span>X</span>
        </a>
        <h1 class="product-page__title">{{ data?.name }}</h1>
        There is no data for this product.
    </div>
</div>
