import { Portal } from './interfaces';

export const REFRESH_TOKEN_COOKIE = 'refreshToken';
export const SCHEMA_VERSION = 9;

export const staticPortals: Portal[] = [
    { id: 'EUPortal', value: 'EU Legislation' },
    { id: 'USAPortal', value: 'US Legislation' },
    { id: 'TECHPortal', value: 'BSA' },
];
