<div class="top-bar">
    <figure class="top-bar__logo" [inlineSVG]="'assets/images/logo.svg'">
        <figcaption class="element-hidden">Palsgaard logo</figcaption>
    </figure>
    <div class="top-bar__content">
        <ng-container *ngIf="authenticated | async">
            <app-main-navigation></app-main-navigation>
            <app-status-menu></app-status-menu>
            <app-portals></app-portals>
        </ng-container>
        <app-user class="top-bar__item"></app-user>
    </div>
</div>
